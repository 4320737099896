import React from 'react';
import { ProductProvider } from './src/context/ProductContext';
import { ContextProvider } from './src/context/ContextData';

export const wrapRootElement = ({ element }) => (
  <ProductProvider>
    <ContextProvider>
      {element}
    </ContextProvider>
  </ProductProvider>
);
