// static-data.js
const categoryPages = [
  {
    title: 'Душові кабіни',
    page: 'shower-cabins'
  },
  {
    title: 'Душові двері',
    page: 'shower-doors'
  },
  {
    title: 'Перегородки',
    page: 'shower-partitions'
  },
  {
    title: 'Шторки',
    page: 'shower-curtains'
  }
];
const allFilters = [
  {
    title: 'Тип форми',
    id: 'shape_type',
    shape_type: [
      {
        title: 'Квадратні',
        id: 'square',
      }, 
      {
        title: 'Прямокутні',
        id: 'rectangular',
      }, 
      {
        title: 'Пʼятикутні',
        id: 'pentagonal',
      }, 
      {
        title: 'Вхід з кута кабіни',
        id: 'corner_entry',
      }
  ]
  }, {
    title: 'Тип відкривання',
    id: 'opening_type',
    opening_type: [{
      title: 'Відкривного типу',
      id:'opening',
    }, {
      title: 'Розсувного типу',
      id:'sliding',
    }, {
      title: 'Відкривні в дві сторони',
      id: 'double_opening',
    }, {
      title: 'Розсувні в дві сторони',
      id: 'double_sliding',
    }]
  }
];


const filtersAvailable = ['shower-cabins', 'shower-doors']

const firebaseConfig = {
  apiKey: "AIzaSyCXMfPiMoov4suA5j7QFb0OetNM3gWcXos",
  authDomain: "kab-weston.firebaseapp.com",
  projectId: "kab-weston",
  storageBucket: "kab-weston.appspot.com",
  messagingSenderId: "1083608029500",
  appId: "1:1083608029500:web:8759d98dd985c67558e639",
  measurementId: "G-3CVR2D9V06"
};

const pricesConfigTitle = [
  {
    height: {
      title: 'Висота',
    }
  },
  {
    shower_cabins_size: {
      title: 'Ширина (бічна x вхідна)',
    }
  },
  {
    shower_partitions_size: {
      title: 'Ширина перегородки',
      nestedSizesPricesPerMeter: true, 
      // keyGroup: 'sizes_partitions_group',
      // configsKey: [
      //   'sizes_partitions_group', 
      //   'doorway_size_group', 
      //   'side_panel_group', 
      // ]
      // configs: {
      //   sizes_partitions_group: {
      //     title: 'Розміри для перегородок',
      //     priceAndDynamicSize: true
      //   },
      //   doorway_size_group: {
      //     title: 'розміри входу',
      //     priceAndDynamicSize: true
      //   },
      //   side_panel_group: {
      //     title: 'Секція поруч зі входом',
      //     priceAndDynamicSize: true
      //   },
      // }
      nested_sets: [
        "sizes_partitions_group",
        "doorway_size_group",
        "side_panel_group"
      ]
    }
  },
  //*================================================================================================================================ 
  
  {
    sizes_partitions_group: {
      title: 'Ширина перегородки',
      priceAndDynamicSize: true
    }
  },
  {
   doorway_size_group: {
      title: 'Розміри входу',
      priceAndDynamicSize: true
    }
  },
  {
    side_panel_group: {
      title: 'Секція поруч зі входом',
      priceAndDynamicSize: true
    }
  },
  //*================================================================================================================================ 
  
  {
    doorway_size: {
      title: 'Ширина входу',
    }
  },
  {
    side_panel: {
      title: 'Розміри секцій поруч із входом',
    }
  },
  {
    shower_doors_size: {
      title: 'Ширина виробу',
    }
  },
  {
    shower_curtains_size: {
      title: 'Ширина шторки',
    }
  },
  {
    door: {
      title: 'Ширина дверей',
    }
  },
  {
    fixed_panel: {
      title: 'Ширина нерухомої частини',
    }
  },
  // {
  //   pivot_element: {
  //     title: 'Ширина поворотного елемента',
  //   }
  // },
  {
    glass: {
      title: 'Обробка скла',
      configs:{
        without_matting: 'Без матування' ,
        solid_matting: 'Суцільне матування' ,
        intimate_zone: 'Інтим-зона' ,
        artistic_matting: 'Художнє матування' 
      }
    }
  },
  {
    shower_cabins_protective: {
      title: 'Захисне покриття',
      configs: {
        no_protective: 'Без покриття' ,
        internal: 'Внутрішнє' ,
        external: 'Зовнішнє' ,
        internal_external: 'Внутрішнє та зовнішнє' 
      }
    }
  },
  {
    shower_cabins_color_furniture: {
      title: 'Колір фурнітури',
      configs: {
        polished: 'Полірована' ,
        black: 'Чорна' 
      }
    
    }
  },
  {
    shower_cabins_handle: {
      title: 'Ручка',
      configs: { 
        knob: 'Кноб',
        towel_holder: 'Ручка-тримач для рушника',
        round: 'Кругла' 
      }
    }
  },
  {
    contourFrame: {
      title: 'Окантування по контуру',
      configs: { 
        withoutEdging: 'Без окантування',
        edging: 'З окантуванням',
      }
    }
  },
  {
    top_corner: {
      title: 'Верхній кут',
      configs: { 
        sharp: 'Гострий',
        rounded: 'Заокруглений',
      }
    }
  },
  {
    shower_cabins_hinge_placement: {
      title: 'Розміщення петель',
      configs: {
        right: 'Справа',
        left: 'Зліва' 
      }
    }
  },
  {
    opened_door_side: {
      title: 'Сторона відчинених дверей',
      configs: {
        right: 'Справа',
        left: 'Зліва' 
      }
    }
  },
  {
    mounting_side: {
      title: 'Сторона монтажу',
      configs: {
        right: 'Справа',
        left: 'Зліва' 
      }
    }
  },
  {
    doorSealType: {
      title: 'Тип ущільнювача дверей',
      configs: {
        nonMagneticDoorSealType: 'Без магнітної фіксації',
        magneticDoorSealType: 'З магнітною фіксацією' 
      }
    }
  }
]

module.exports = { firebaseConfig, categoryPages, allFilters, filtersAvailable, pricesConfigTitle };
