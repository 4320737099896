module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":" ","exclude":["**/dev-404-page/**","**/404/**","**/404.html","**/offline-plugin-app-shell-fallback/**"],"crumbLabelUpdates":[{"pathname":"/","crumbLabel":""},{"pathname":"/catalog","crumbLabel":"Каталог"},{"pathname":"/calculate","crumbLabel":"Калькулятор"},{"pathname":"/calculate","crumbLabel":"Калькулятор"},{"pathname":"/advantages","crumbLabel":"Переваги"},{"pathname":"/catalog/shower-cabins","crumbLabel":"Душові кабіни"},{"pathname":"/catalog/shower-doors","crumbLabel":"Душові двері"},{"pathname":"/catalog/shower-partitions","crumbLabel":"Перегородки"},{"pathname":"/catalog/shower-curtains","crumbLabel":"Шторки"}],"trailingSlashes":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b3d92a6624b81042918cbf7b51ca9da3"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
