import { useEffect, useState } from "react";
import { collection, getDocs } from 'firebase/firestore/lite'; 
import { ref, getDownloadURL } from "firebase/storage";

const { db, storage } = require(`@root/firebaseConfig.js`);

const useFetchProducts = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      const timeout = setTimeout(() => {
        setLoading(false);
        setError('Час очікування відповіді закінчився. Будь ласка, спробуйте ще раз.');
        console.error('Час очікування відповіді закінчився. Будь ласка, спробуйте ще раз.');
      }, 10000); 

      try {
        const querySnapshot = await getDocs(collection(db, 'products'));
        clearTimeout(timeout);

        const productsData = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const data = doc.data();

            let imagesUrl = [];
            let instructionsUrl = null;

            try {
              // Отримання масиву зображень
              imagesUrl = data.images && await Promise.all(
                data.images.map(async (imagePath) => {
                  return await getDownloadURL(ref(storage, imagePath));
                })
              );

              // Отримання URL для PDF інструкції
              if (data.instructions) {
                instructionsUrl = await getDownloadURL(ref(storage, data.instructions));
              }

            } catch (error) {
              console.error('Error fetching URLs:', error);
              setError('Error fetching URLs');
            }

            if (data.available && data.title) {
              return {
                id: doc.id,
                title: data.title,
                prices: data.prices,
                description: data.description,
                images: imagesUrl,
                available: data.available,
                hot_products: data.hot_products,
                category: data.category,
                filters: data.filters,
                discount: data.discount,
                instructions: instructionsUrl // Додайте URL інструкції до об'єкта
              };
            }
          })
        );

        setProducts(productsData.filter(product => product));
      } catch (fetchError) {
        clearTimeout(timeout);
        console.error('Error fetching data:', fetchError);
        setError('Error fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);
  
  return { products, loading, error };
};

export default useFetchProducts;
